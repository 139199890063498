import Hierarchy from '../services/hierarchy.service';

export default {
  state: {
    standards: [],
    standardDetails: [],
  },
  getters: {
    Standards: (state) => state.standards,
    StandardDetails: (state) => state.standardDetails,
  },
  mutations: {
    SET_STANDARDS(state, payload) {
      state.standards = payload;
    },
    SET_STANDARD_DETAILS(state, payload) {
      state.standardDetails = payload;
    },
  },
  actions: {
    async getStandards({ commit, state }, force) {
      if ((state.standards && state.standards.length === 0) || force) {
        const standardsPromise = Hierarchy.Standards.GetStandards();
        const standardDetailsPromise = Hierarchy.Standards.GetStandardDetails();

        const results = await Promise.all([standardsPromise, standardDetailsPromise]);

        results[0].items.forEach((i) => {
          i.displayName = i.title.match(/[A-Z:(0-9)]/g).join('');
          results[1].items.forEach((sd) => {
            if (sd.docIdentifier === i.identifier) {
              sd.standardName = i.displayName;
            }
          });
        });

        commit('SET_STANDARDS', results[0].items);
        commit('SET_STANDARD_DETAILS', results[1].items);
      }
    },
    async getStandardDetails({ commit, state }, force) {
      if ((state.standardDetails && state.standardDetails.length === 0) || force) {
        const standardDetails = await Hierarchy.Standards.GetStandardDetails();
        commit('SET_STANDARD_DETAILS', standardDetails.items);
      }
    }
  }
}