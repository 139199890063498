<template>
  <div>
    <v-snackbar
      v-model="showError"
      color="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showError = false">
          Dismiss
        </v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-row class="form-row">
        <v-col sm="6" lg="4" xl="3">
          <v-list v-if="currentUI === 'VENDOR_LIST'">
            <v-subheader>Select Source</v-subheader>
            <v-list-item-group
              color="primary">
              <v-list-item
                v-for="(vendor, index) in vendors"
                :key="index"
                @click="selectVendor(vendor)">
                <v-list-item-icon>
                  <v-icon>mdi-school</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ vendor.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ vendor.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="showUploadUI">
                <v-list-item-icon>
                  <v-icon>mdi-folder-zip</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Offline Package</v-list-item-title>
                  <v-list-item-subtitle>File Upload from local system</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div v-if="currentUI === 'PACKAGE_LIST'">
            <v-list class="scroll-content with-buttons">
              <v-subheader>Select Package</v-subheader>
              <v-list-item-group
                color="primary">
                <v-list-item
                  v-for="(item, index) in packages"
                  :key="index"
                  @click="selectPackage(item)">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Text }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.Desc }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-row class="mt-1">
              <v-col lg="6" sm="12">
                <v-checkbox class="pa-1"
                  v-model="overrideTitle"
                  label="Override Titles">
                  <template v-slot:label>
                    Override Titles
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                      </template>
                      <span>Check this box to override existing titles with those from the imported package.</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col lg="6" sm="12" align="right" class="">
                <v-btn @click="backClick" class="mr-2">Back</v-btn>
                <v-btn
                  :disabled="!selectedPackage.Text"
                  @click="packageImport"
                  color="green darken-1">Import</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>Packages from {{ VendorPackageListTime }}</v-col>
            </v-row>
          </div>
          <div v-if="currentUI === 'FILE_UPLOAD'">
            <v-row>
              <v-col>
                <v-file-input
                  accept="application/zip"
                  label="Package File"
                  v-model="packageFile">
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  accept="application/csv"
                  label="Tag File"
                  v-model="tagFile">
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" md="12">
                <v-checkbox
                  v-model="overrideTitle"
                  label="Override Titles">
                  <template v-slot:label>
                    Override Titles
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                      </template>
                      <span>Check this box to override existing titles with those from the imported package.</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col lg="6" md="12" align-self="end">
                <v-row align-content="end">
                  <v-col align="right" align-self="end">
                    <v-btn @click="backClick">Back</v-btn>
                  </v-col>
                  <v-col lg="5" md="4" align="right" align-self="end">
                    <v-btn
                      :disabled="!packageFile"
                      @click="fileUpload"
                      color="green darken-1">Import</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col sm="6" lg="8" xl="9" class="content-col scroll-content">
          Previous Import Status
          <div class="table-container">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Source</th>
                    <th class="text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(status, index) in statuses" :key="index">
                    <td>{{ status.Date }}</td>
                    <td>{{ status.Source }}</td>
                    <td>
                      {{ status.Status }}
                      <v-btn v-if="status.Status === 'Failed'" icon
                        @click="showDialog(status.Detail)">
                        <v-icon color="red">mdi-alert-circle</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div style="text-align: right;">
            <span>Status as of: {{ LastRunTime }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      width="50%"
      v-model="alertDialog.show">
      <v-card>
        <v-card-title>Error Descirption</v-card-title>
        <v-card-text>{{ alertDialog.text }}</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import qtiService from '../services/qti.service';

import timerVue from '../mixins/timer.vue';

export default {
  mixins: [timerVue],
  data() {
    return {
      selectedItem: {},
      selectedPackage: {},
      currentUI: 'VENDOR_LIST',
      importInProgress: false,
      packageFile: null,
      tagFile: null,
      showError: false,
      errorMessage: '',
      overrideTitle: false,
      alertDialog: {
        show: false,
        text: '',
      },
    };
  },
  props: {
    curriculumGuid: {
      type: String
    },
  },
  mounted() {
    this.$store.dispatch('getVendors');
  },
  computed: {
    vendors() {
      return this.$store.getters.Vendors;
    },
    packages() {
      return this.$store.getters.Packages;
    },
    statuses() {
      return this.$store.getters.HangfireImportStatus;
    },
    VendorPackageListTime() {
      return this.$store.getters.PackageLoadTime.toLocaleString();
    },
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    showUploadUI() {
      this.currentUI = 'FILE_UPLOAD';
    },
    showDialog(text) {
      this.alertDialog.text = text;
      this.alertDialog.show = true;
    },
    selectVendor(vendor) {
      this.selectedItem = vendor;
      this.$store.dispatch('getVendorPackages', vendor.id);
      this.currentUI = 'PACKAGE_LIST';
    },
    selectPackage(pkg) {
      if (this.selectedPackage.reference === pkg.reference) {
        this.selectedPackage = {};
      } else {
        this.selectedPackage = pkg;
      }
    },
    backClick() {
      this.currentUI = 'VENDOR_LIST';
    },
    fileUpload() {
      this.$store.dispatch('toggleLoadingOverride');
      qtiService.Packages.UploadOfflinePackage(
        this.$store.getters.CurriculumGuid,
        this.$store.getters.WebconUser,
        this.packageFile,
        this.tagFile,
        this.overrideTitle,
        this.uploadProgress
      ).catch((error) => {
        this.errorMessage = error.message
        this.showError = true;
      }).then(() => {
        this.currentUI = 'VENDOR_LIST';
        this.packageFile = null;
        this.tagFile = null;
        this.overrideTitle = false;
        this.$store.dispatch('toggleLoadingOverride');
      });
      this.$store.dispatch('clearAssessmentData');
      this.$store.dispatch('getQtiAssessments', this.$store.getters.CurriculumGuid);
    },
    packageImport() {
      this.$store.dispatch('toggleLoadingOverride');
      qtiService.Packages.ImportPackage(
        this.$store.getters.CurriculumGuid,
        this.$store.getters.WebconUser,
        this.selectedItem.id,
        this.overrideTitle,
        [this.selectedPackage.reference])
      .then(() => {
        this.$store.dispatch('getHangfireStatuses');
        this.currentUI = 'VENDOR_LIST';
        this.selectedPackage = {};
        this.selectedItem = {};
        this.overrideTitle = false;
        this.$store.dispatch('toggleLoadingOverride');
      });
      this.$store.dispatch('clearAssessmentData');
      this.$store.dispatch('getQtiAssessments', this.$store.getters.CurriculumGuid);
    },
    uploadProgress(event) {
      console.log(event);
    }
  },
}
</script>

<style scoped>
.package-list {
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.table-container {
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
}
</style>