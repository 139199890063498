<template>
  <v-container fluid>
    <v-row class="form-row no-buttons">
      <v-col sm="6" lg="4" xl="3" class="pt-0">
        <tree-view
          tree-container-class="scroll-content no-buttons"
          :show-checkbox="false"
          :curriculum-guid="curriculumGuid"
          @selected="itemSelected"
          @checked="itemChecked"></tree-view>
      </v-col>
      <v-col sm="6" lg="8" xl="9" class="content-col scroll-content">
        <v-row>
          <v-col>
            <curriculum-details v-if="SelectedNodeType === 'Curriculum'">
            </curriculum-details>
            <assessment-details v-if="SelectedNodeType === 'Assessment'">
            </assessment-details>
            <question-details v-if="SelectedNodeType === 'Question'">
            </question-details>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <tag-listing v-if="selectedNode.type && SelectedNodeType !== 'Curriculum'"></tag-listing>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <standards-panel
              v-if="selectedNode.type && SelectedNodeType !== 'Curriculum'">
            </standards-panel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TreeView from '../components/TreeView.vue';
import CurriculumDetails from '../components/CurriculumDetails.vue';
import AssessmentDetails from '../components/AssessmentDetails.vue';
import QuestionDetails from '../components/QuestionDetails.vue';
import TagListing from '../components/TagListing.vue';
import StandardsPanel from '../components/StandardsPanel.vue';

export default {
  props: {
    curriculumGuid: {
      type: String,
    },
    webconUser: {
      type: String,
    },
  },
  components: {
    TreeView,
    CurriculumDetails,
    AssessmentDetails,
    QuestionDetails,
    TagListing,
    StandardsPanel,
  },
  data() {
    return {
      selectedNode: {},
    };
  },
  computed: {
    QtiQuestion() {
      return this.$store.getters.QtiQuestions.filter(qst => `q.${qst.id}` === this.selectedNode.uniqueId)[0];
    },
    QtiAssessment() {
      return this.$store.getters.QtiAssessments.filter(asmt => `a.${asmt.id}` === this.selectedNode.uniqueId)[0];
    },
    Curriculum() {
      return this.$store.getters.Curriculum;
    },
    SelectedNodeType() {
      switch (this.selectedNode.type) {
        case 'TEST':
          return 'Assessment';
        case 'QUESTION':
          return 'Question';
        case 'ROOTFOLDER':
          return 'Curriculum';
        default:
          return '';
      }
    }
  },
  methods: {
    itemSelected(node) {
      this.$store.dispatch('toggleLoadingOverride');
      console.debug('Node Selected: %O', node);
      this.selectedNode = node;
      if (!node.name) {
        this.$store.dispatch('clearSelectedNode');
      } else {
        this.$store.dispatch('setSelectedNode', node);
      }
      this.$store.dispatch('toggleLoadingOverride');
    },
    itemChecked(node) {
      console.debug('Node Checked: %O', node);
    },
  },
}
</script>

<style scoped>
</style>