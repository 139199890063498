<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <v-text-field placeholder="Search QTI"
          class="mr-2"
          v-if="showSearch"
          @keydown="reOpenNodes"
          @focus="setFocus"
          v-model="searchString">
          <v-icon slot="append">mdi-folder-search-outline</v-icon>
        </v-text-field>
        <v-select v-if="showOptionFilter"
          class="mr-2"
          :items="filterOptions"
          :label="filterOptionsPlaceholder"
          v-model="filterOption">
        </v-select>
      </v-card-title>
      <v-card-text :class="treeContainerClass">
        <v-treeview
          :items.sync="items"
          :open.sync="openNodes"
          item-key="uniqueId"
          open-all
          return-object
          dense
          activatable
          selection-type="leaf"
          :selectable="showCheckbox"
          v-model="selectedNodes"
          @update:active="nodeSelected"
          @input="nodeChecked">
          <template v-slot:prepend="{item}">
            <v-icon>{{ getIcon(item) }}</v-icon>
          </template>
          <template v-slot:label="{item}">
            <span :class="backgroundColor(item.name)">{{ item.name }}</span>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  emits: ['selected', 'checked'],
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    curriculumGuid: {
      type: String,
    },
    webconUser: {
      type: String,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showOptionFilter: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Array,
      default: () => [
        {
          text: 'Platform 1',
          value: 'Plat1',
        },
        {
          text: 'Platform 2',
          value: 'Plat2',
        }
      ]
    },
    filterOptionObjectParam: {
      type: String,
    },
    filterOptionsPlaceholder: {
      type: String,
    },
    showUnpublishableIcon: {
      type: Boolean,
      default: false,
    },
    treeContainerClass: {
      type: String,
      default: 'tree-container'
    },
  },
  data: () => {
    return {
      openNodes: [],
      selectedNodes: [],
      searchString: '',
      openAllNodes: false,
      hasFocus: false,
      filterOption: '',
    }
  },
  computed: {
    items() {
      return this.$store.getters.QtiTree;
    }
  },
  methods: {
    backgroundColor(itemName) {
      if (this.searchString.length === 0 || itemName?.length === 0) {
        return '';
      }
      if (itemName.toUpperCase().search(this.searchString.toUpperCase()) >= 0) {
        return 'search-target';
      }
    },
    getIcon(item) {
      if (item[this.$props.filterOptionObjectParam] && item[this.$props.filterOptionObjectParam].length > 0) {
        // Process Object Filter
        if (this.filterOption && this.filterOption.length > 0 &&
          !item[this.$props.filterOptionObjectParam].toUpperCase().includes(this.filterOption.toUpperCase())) {
            return 'mdi-alpha-x-box';
          }
      }
      if (item.type.toUpperCase() === 'FOLDER' || item.type.toUpperCase() === 'ROOTFOLDER') {
        return 'mdi-folder';
      }
      if (item.type.toUpperCase() === 'TEST') {
        return 'mdi-file-document-edit-outline';
      }
      return 'mdi-help';
    },
    getParentNodes(items) {
      const parents = [];
      items.forEach(obj => {
        if (obj.children && obj.children.length > 0) {
          parents.push(obj);
          parents.push(this.getParentNodes(obj.children))
        }
      });
      return parents.flat();
    },
    nodeSelected(item) {
      console.debug('TreeView:nodeSelected - %o', item);
      if (item.length === 1) {
        this.$emit('selected', item[0]);
      } else {
        this.$emit('selected', {});
      }
    },
    nodeChecked(selectedItems) {
      this.$emit('checked', selectedItems);
    },
    setFocus() {
      if (!this.hasFocus) {
        this.hasFocus = true;
      }
    },
    reOpenNodes() {
      if (this.hasFocus) {
        this.expandAllNodes();
        this.hasFocus = false;
      }
    },
    expandAllNodes() {
      this.openNodes = this.getParentNodes(this.items).flat();
    }
  },
  mounted() {
    this.$store.dispatch('getQtiAssessments', this.$props.curriculumGuid);
  },
  watch: {
    items() {
      this.openNodes = this.getParentNodes(this.items);
    }
  },
}
</script>

<style scoped>
.tree-container {
  padding-right: 5px;
  height: 450px;
  overflow-y: scroll;
}

.search-target {
  background-color: yellow;
}
</style>