<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header class="section" :expand-icon="expandedIcon">Standards</v-expansion-panel-header>
      <v-expansion-panel-content>
        <standard-select
          :selected-node="selectedNode"
          :applied-standards="appliedStandards">
        </standard-select>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import StandardSelect from './StandardSelect.vue';

export default {
  components: {
    StandardSelect,
  },
  computed: {
    expandedIcon() {
      if (this.expanded === 0) {
        return 'mdi-minus';
      }
      return 'mdi-plus';
    },
    selectedNode() {
      return this.$store.getters.SelectedNode;
    },
    appliedStandards() {
      return this.$store.getters.SelectedNodeStandards;
    }
  }
}
</script>

<style scoped>
.v-expansion-panel-header.section {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.v-expansion-panel-header--active {
  padding-top: 0;
  padding-bottom: 0;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 32px;
}

</style>