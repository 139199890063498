<template>
  <v-container fluid class="page-container">
    <v-expansion-panels accordion focusable>
      <v-expansion-panel v-for="(tag, idx) in tags" :key="idx">
        <v-expansion-panel-header @click="loadSelectedTag(tag, $event)">{{ tag.key }} - {{ tag.value }} ({{ tag.tagCount }} instances)</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
          <standard-select v-else
            :selected-tag="tag"
            :applied-standards="appliedStandards"
            @save="saveStandards"
            @delete="deleteStandards">
            <template v-slot:buttonRow>
              <v-btn 
                v-if="appliedStandards.length > 0"
                text color="green lighten-2"
                @click="regenerateCorrelations(tag)">Regenerate</v-btn>
            </template>
          </standard-select>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import StandardSelect from '../components/StandardSelect.vue';
import Qti from '../services/qti.service';

export default {
  components: {
    StandardSelect,
  },
  data() {
    return {
      loading: true,
      appliedStandards: [],
      currentTag: {},
    };
  },
  computed: {
    tags() {
      return this.$store.getters.UniqueTags;
    },
  },
  methods: {
    async loadSelectedTag(tag, evt) {
      if (evt && evt.target && evt.target.classList.contains('v-expansion-panel-header--active')) {
        // We are closing the expansion
        this.currentTag = null;
        return;
      }
      this.loading = true;
      this.currentTag = tag;
      this.appliedStandards = await Qti.Tags.GetCorrelations(tag.key, tag.value);
      let standard;
      this.appliedStandards.forEach((std) => {
        standard = this.$store.getters.StandardDetails.filter((s) => s.identifier === std.caseIdentifier)[0];
        std.displayName = standard.standardName;
        std.code = standard.humanCodingScheme;
        std.name = standard.fullStatement;
      });
      this.loading = false;
    },
    async saveStandards(args) {
      const tag = args.tag;
      const standards = args.standards;
      const savePromises = [];

      standards.forEach((std) => {
        savePromises.push(Qti.Tags.CreateCorrelation(
          tag.key,
          tag.value,
          std.identifier,
          std.docIdentifier,
          this.$store.getters.WebconUser
        ));
      });

      await Promise.all(savePromises);

      this.loadSelectedTag(tag);
    },
    async deleteStandards(args) {
      const tag = args.tag;
      const standards = args.standards;
      const deletePromises = [];

      standards.forEach((std) => {
        deletePromises.push(Qti.Tags.DeleteCorrelation(std.id));
      });

      await Promise.all(deletePromises);

      this.loadSelectedTag(tag);
    },
    async regenerateCorrelations(tag) {
      console.log(tag);
      await Qti.Tags.RegenerateCorrelations(this.$store.getters.CurriculumGuid, tag);
    }
  },
  mounted() {
    this.$store.dispatch('getUniqueTags');
  }
}
</script>

<style scoped>
.page-container {
  margin-top: 15px;
  height: 500px;
  max-height: 500px;
  overflow-y: scroll;
}
</style>