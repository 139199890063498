<template>
  <v-expansion-panels accordion v-model="expanded">
    <v-expansion-panel>
      <v-expansion-panel-header class="section" :expand-icon="expandedIcon">Applied Tags</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          dense
          :items="tags"
          :headers="tableHeaders">

        </v-data-table>
        <!-- <v-expansion-panels>
          <v-expansion-panel v-for="(tag, idx) in tags" :key="idx">
            <v-expansion-panel-header>{{ tag.key }} - {{  tag.value }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <standard-select></standard-select>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// import StandardSelect from './StandardSelect.vue';

export default {
  components: {
    // StandardSelect,
  },
  data() {
    return {
      expanded: [],
      tableHeaders: [
        { text: 'Tag Name', align: 'start', sortable: false, value: 'key' },
        { text: 'Tag Value', align: 'start', sortable: false, value: 'value'}
      ]
    }
  },
  computed: {
    expandedIcon() {
      if (this.expanded === 0) {
        return 'mdi-minus';
      }
      return 'mdi-plus';
    },
    selectedNode() {
      return this.$store.getters.SelectedNode;
    },
    tags() {
      if (!this.selectedNode) { return []; }
      switch (this.selectedNode.type) {
        case 'ROOTFOLDER':
          return this.$store.getters.QtiTags.filter((t) => {
            return t.curriculumGuid.toLowerCase() === this.$store.getters.CurriculumGuid.toLowerCase() &&
            !t.assessmentReference &&
            !t.questionReference;
          });
        case 'TEST':
          return this.$store.getters.QtiTags.filter((t) => {
            return t.curriculumGuid.toLowerCase() === this.$store.getters.CurriculumGuid.toLowerCase() &&
            t.assessmentReference === this.selectedNode.sysId &&
            !t.questionReference;
          });
        case 'QUESTION':
          return this.$store.getters.QtiTags.filter((t) => {
            return t.curriculumGuid.toLowerCase() === this.$store.getters.CurriculumGuid.toLowerCase() &&
            t.assessmentReference === this.selectedNode.assessmentReference &&
            t.questionReference === this.selectedNode.sysId
          });
        default:
          return [];
      }
    }
  }
}
</script>

<style scoped>
.v-expansion-panel-header.section {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.v-expansion-panel-header--active {
  padding-top: 0;
  padding-bottom: 0;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 32px;
}
</style>