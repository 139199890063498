import axios from 'axios';

const rootUrl = process.env.VUE_APP_QTI_URL;

const apiKey = process.env.VUE_APP_QTI_ID;
const apiSecret = process.env.VUE_APP_QTI_SECRET;

const tokenUrl = `${rootUrl}/connect/token`;

let token = '';
let tokenExpire = '';

async function doAuth() {
  if (token && tokenExpire > new Date()) {
    return token;
  }
  const url = tokenUrl;
  const response = await axios(
    {
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: `grant_type=client_credentials&scope=api&client_id=${apiKey}&client_secret=${apiSecret}`,
    }
  );
  token = response.data.access_token;
  const dt = new Date();
  tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
}

export default {
  Assessments: {
    async GetAll(curriculumGuid) {
      if (process.env.VUE_APP_USE_DEMO_DATA.indexOf('ASSESSMENT') >= 0) {
        // Return development data
      }

      await doAuth();

      let url = `${rootUrl}/assessments?curriculumGuid=${curriculumGuid}`;

      const response = await axios({
        url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
    async Save(assessment, webconUser) {
      if (process.env.VUE_APP_USE_DEMO_DATA.indexOf('ASSESSMENT') >= 0) {
        return;
      }

      await doAuth();

      let url = `${rootUrl}/assessments`;

      const response = await axios({
        url,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify(assessment)
      });

      if (response.status === 200) {
        // Handle Success
        return response.data;
      } else {
        // Handle Error
      }
    }
  },
  Questions: {
    async GetAll(curriculumGuid, assessmentGuid) {
      if (process.env.VUE_APP_USE_DEMO_DATA.indexOf('QUESTION') >= 0) {
        return [
          {
            type: 'rootFolder',
            id: 1,
            uniqueId: '1',
            guid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            objectGuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            level: 0,
            name: 'Computer Science',
            url: 'string',
            sysId: 'string',
            instanceId: 0,
            sortOrder: 0,
            hCode: 'string',
            publishable: true,
            unPublishableReason: 'string',
            children: [
              {
                type: 'test',
                uniqueId: '1.1',
                name: 'Unit Test 1.1',
                children: [
                  {
                    type: 'question',
                    uniqueId: '1.1.1',
                    name: 'Question 1',
                    platforms: 'plat2',
                  },
                  {
                    type: 'question',
                    uniqueId: '1.1.2',
                    name: 'Question 2',
                    platforms: 'plat1,plat2',
                  },
                  {
                    type: 'question',
                    uniqueId: '1.1.3',
                    name: 'Question 3',
                    platforms: 'plat1',
                  },
                ]
              },
              {
                type: 'test',
                uniqueId: '1.2',
                name: 'Unit Test 1.2',
                children: [
                  {
                    type: 'question',
                    uniqueId: '1.2.1',
                    name: 'Question 1',
                    platforms: 'plat1,plat2',
                  },
                  {
                    type: 'question',
                    uniqueId: '1.2.2',
                    name: 'Question 2',
                    platforms: 'plat1,plat2',
                  },
                  {
                    type: 'question',
                    uniqueId: '1.2.3',
                    name: 'Question 3',
                    platforms: 'plat1,plat2',
                  },
                ]
              },
              {
                type: 'test',
                uniqueId: '1.3',
                name: 'Unit Test 1.3',
                children: [
                  {
                    type: 'question',
                    uniqueId: '1.3.1',
                    name: 'Question 1',
                    platforms: 'plat1,plat2',
                  },
                  {
                    type: 'question',
                    uniqueId: '1.3.2',
                    name: 'Question 2',
                    platforms: 'plat1,plat2',
                  },
                  {
                    type: 'question',
                    uniqueId: '1.3.3',
                    name: 'Question 3',
                    platforms: 'plat1',
                  },
                ]
              },
            ]
          }
        ];
      }
      
      await doAuth();

      let url = `${rootUrl}/questions?curriculumGuid=${curriculumGuid}${assessmentGuid ? `&assessmentGuid=${assessmentGuid}` : ''}`;
      
      const response = await axios({
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    async Get(questionId) {
      await doAuth();

      let url = `${rootUrl}/questions/${questionId}`;
      
      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

      return response.data;
    }
  },
  Tags: {
    async GetAll(curriculumGuid, assessmentReference, questionReference) {
      await doAuth();

      let url = `${rootUrl}/tags?curriculumGuid=${curriculumGuid}`;

      if (assessmentReference && assessmentReference.length > 0) {
        url = `${url}&assessmentReference=${assessmentReference}`;
      }

      if (questionReference && questionReference.length > 0) {
        url = `${url}&questionReference=${questionReference}`;
      }

      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }).catch(err => {
          console.error(err);
        });

      return response.data;
    },
    async GetUnique(curriculumGuid) {
      await doAuth();

      let url = `${rootUrl}/tags/unique?curriculumGuid=${curriculumGuid}`;

      const response = await axios({
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
    async GetCorrelations(key, value) {
      await doAuth();

      let url = `${rootUrl}/tags/correlations?key=${key}&value=${value}`;

      const response = await axios({
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
    async CreateCorrelation(key, value, caseIdentifier, caseDocIdentifier, webconUser) {
      await doAuth();

      let url = `${rootUrl}/tags/correlations`;

      const response = await axios({
        url: url,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: JSON.stringify({
          key: key,
          value: value,
          caseIdentifier: caseIdentifier,
          caseDocIdentifier: caseDocIdentifier,
          modUser: webconUser,
          createUser: webconUser,
        })
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
    async DeleteCorrelation(id) {
      await doAuth();

      let url = `${rootUrl}/tags/correlations/${id}`;

      const response = await axios({
        url: url,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
    async RegenerateCorrelations(curriculumGuid, tag) {
      await doAuth();

      let query = `?curriculumGuid=${curriculumGuid}`;
      
      if (tag && tag.key) {
        query += `&key=${tag.key}`;
      }
      if (tag && tag.value) {
        query += `&value=${tag.value}`;
      }

      let url = `${rootUrl}/tags/correlations/regenerate${query}`;

      const response = await axios({
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
  },
  Vendors: {
    async GetAll() {
      await doAuth();

      let url = `${rootUrl}/vendors`;
      
      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

      return response.data;
    },
    async Get(vendorId) {
      await doAuth();

      let url = `${rootUrl}/vendors/${vendorId}`;
      
      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

      return response.data;
    },
  },
  Packages: {
    async GetAll(vendorId, webconUser) {
      if (process.env.VUE_APP_USE_DEMO_DATA.indexOf('PACKAGE') >= 0) {
        return [
          { Text: 'Unit 1 - Final Exam', Desc: 'Unit 1 final test' },
          { Text: 'Unit 2 - Final Exam', Desc: 'Unit 2 final test' },
          { Text: 'Unit 3 - Final Exam', Desc: 'Unit 3 final test' },
        ];
      }

      await doAuth();

      let url = `${rootUrl}/learnosity/activities?vendorId=${vendorId}`;
      
      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            'webcon-user': webconUser,
          }
        }).catch(err => {
          console.error(err);
        });

        return response.data;
    },
    async UploadOfflinePackage(curriculumGuid, webconUser, packageFile, tagsFile, overrideNames, progressCallBack) {
      await doAuth();

      const formData = new FormData();
      formData.append('File', packageFile);
      formData.append('Tags', tagsFile);

      let url = `${rootUrl}/learnosity/offlinepackage/to/qti?curriculumGuid=${curriculumGuid}&overrideNames=${overrideNames}`;

      const response = await axios.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
            'webcon-user': webconUser,
          },
          progressCallBack,
        });

      return response.data;
    },
    async ImportPackage(curriculumGuid, webconUser, vendorId, overrideNames, activities) {
      await doAuth();

      let url = `${rootUrl}/Learnosity/offlinepackage/vendor/${vendorId}/to/qti?curriculumGuid=${curriculumGuid}&overrideNames=${overrideNames}`;

      const response = await axios({
        url: url,
        method: 'POST',
        data: {
          activityReferences: activities,
          tags: {},
        },
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        }
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    },
    async PublishPackage(curriculumGuid, webconUser, standardsGuid, mathMlOption, publishPackage) {
      await doAuth();
      
      let url = `${rootUrl}/Package/generate/qti?curriculumGuid=${curriculumGuid}&standardSetGuid=${standardsGuid}&mathMlOptions=${mathMlOption}`;

      const response = await axios({
        url: url,
        method: 'POST',
        data: publishPackage,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
      }).catch(err => {
        console.error(err);
      });

      return response.data;
    }
  },
  HangfireJobs: {
    async GetImportJobs(curriculumGuid) {
      if (process.env.VUE_APP_USE_DEMO_DATA.indexOf('HANGFIRE') >= 0) {
        return [
          { Date: '12/12/2022 8:32 PM', Source: 'Lernosity - PCG Account', Status: 'Pending'},
          { Date: '11/9/2022 10:12 PM', Source: 'Local Zip File Upload', Status: 'Pending'},
          { Date: '10/24/2022 12:15 PM', Source: 'Lernosity - PCG Account', Status: 'Completed'},
          { Date: '9/25/2022 11:26 PM', Source: 'Lernosity - {Vendor} Account', Status: 'Completed'},
        ];
      }

      await doAuth();

      let url = `${rootUrl}/api/hangfire/jobs?curriculumGuid=${curriculumGuid}&type=1`;

      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }).catch(err => {
          console.error(err);
        });

      return response.data;
    },
    async GetExportJobs(curriculumGuid) {
      if (process.env.VUE_APP_USE_DEMO_DATA.indexOf('HANGFIRE') >= 0) {
        return [
        ];
      }

      await doAuth();

      let url = `${rootUrl}/api/hangfire/jobs?curriculumGuid=${curriculumGuid}&type=2`;

      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }).catch(err => {
          console.error(err);
        });

      return response.data;
    },
  }
}