function sqlDateTimeToJSDate(sqlTime) {
  const parts = sqlTime.split('T');
  const datePart = parts[0];
  const timePart = parts[1];
  const dateParts = datePart.split('-');
  const timeParts = timePart.split(':');

  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  const hour = timeParts[0];
  const minute = timeParts[1];
  const second = timeParts[2].split('.')[0];

  // Subtract 1 from the Month value to get Month Index for Date constructor
  return new Date(year, month - 1, day, hour, minute, second);
}

export default {
  Curriculum: {
    toTreeViewNode(curriculum) {
      return {
        type: 'ROOTFOLDER',
        id: curriculum.id,
        uniqueId: `c.${curriculum.id}`,
        guid: curriculum.curriculumGUID,
        objectGuid: curriculum.curriculumGUID,
        level: 0,
        name: curriculum.curriculumTitle,
        url: '',
        sysId: curriculum.curriculumGUID,
        instanceId: curriculum.id,
        sortOrder: 0,
        hCode: '',
        children: [],
      };
    },
  },
  Assessment: {
    toTreeViewNode(assessment) {
      return {
        type: 'TEST',
        id: assessment.id,
        uniqueId: `a.${assessment.id}`,
        guid: assessment.assessmentReference,
        objectGuid: assessment.assessmentReference,
        level: 1,
        name: assessment.title ? assessment.title : assessment.assessmentReference,
        url: '',
        sysId: assessment.assessmentReference,
        instanceId: assessment.id,
        sortOrder: 0,
        hCode: '',
        children: [],
      }
    },
  },
  Question: {
    toTreeViewNode(question) {
      return {
        type: 'QUESTION',
        id: question.id,
        uniqueId: `q.${question.id}`,
        name: question.title ? question.title : question.questionReference,
        sysId: question.questionReference,
        instanceId: question.id,
        sortOrder: 0,
        assessmentReference: question.assessmentReference,
      }
    },
  },
  Package: {
    toUIObject(packages) {
      const response = [];
      console.log(packages);
      packages.forEach((pkg) => {
        pkg.Text = pkg.description.length > 0 ? pkg.description : pkg.reference;
        pkg.Description = pkg.description;

        response.push(pkg);
      });
      return response;
    }
  },
  HangfireJobs: {
    toUIObject(jobStatuses) {
      const returnList = [];
      jobStatuses.sort((a, b) => b.jobId - a.jobId).forEach(job => {
        returnList.push({
          Date: sqlDateTimeToJSDate(job.modDate).toLocaleString(),
          Source: job.name,
          Status: job.jobStatus,
          Detail: job.jobStatusReason,
        });
      });

      return returnList;
    }
  }
}