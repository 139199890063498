<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel>
      <v-expansion-panel-header :expand-icon="expandIcon">Assessment Details</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row>
            <v-col>
              <span class="property-label">Assessment Name:</span>
              <span>{{ assessment.name }}</span>
              <v-btn icon @click.stop="showEditModal">
                <v-icon size="medium">mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="property-label">Question Count:</span>
              <span>{{ assessment.children.length }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="property-label">Assessment Reference:</span>
              <span>{{ assessment.sysId }}</span>
            </v-col>
          </v-row>
          <v-dialog v-model="showModal" width="600">
            <v-card>
              <v-card-title>Edit Assessment Name</v-card-title>
              <v-card-text>
                <span class="property-label">Assessment Name:</span>
                <v-text-field
                  v-model="assessmentTitle"
                  :value="assessment.name"
                  @focus="$event.target.select()"
                  ref="txtName"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="showModal = false">Cancel</v-btn>
                <v-btn color="primary" text @click="saveUpdate">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      assessmentTitle: '',
      expanded: 0,
    }
  },
  computed: {
    expandIcon() {
      if (this.expanded === 0) {
        return "mdi-minus";
      }
      return "mdi-plus";
    },
    assessment() {
      return this.$store.getters.SelectedNode;
    },
  },
  methods: {
    showEditModal() {
      this.showModal = true;
      this.assessmentTitle = this.assessment.name;
      setTimeout(() => {
        this.$refs.txtName.focus();
      });
    },
    saveUpdate() {
      const assmnt = this.assessment;
      assmnt.name = this.assessmentTitle;
      this.$store.dispatch('updateAssessment', assmnt);
      this.showModal = false;
    }
  },
}
</script>

<style scoped>
.v-expansion-panel-header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.property-label {
  margin: 0 4px 0 0;
  font-weight: bolder;
}
</style>