<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel>
      <v-expansion-panel-header :expand-icon="expandIcon">Curriculum Details</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container flex>
          <v-row>
            <v-col>
              <span class="property-label">Curriculum Name:</span>
              <span class="property">{{ curriculumName }}</span>
            </v-col>
            <v-col>

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="property-label">Description:</span>
              <span class="property">{{ curriculumDesc }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  export default {
    data() {
      return {
        expanded: 0,
      }
    },
    computed: {
      expandIcon() {
        if (this.expanded === 0) {
          return 'mdi-minus';
        }
        return 'mdi-plus';
      },
      curriculum() {
        return this.$store.getters.Curriculum;
      },
      curriculumName() {
        return this.$store.getters.Curriculum.curriculumTitle;
      },
      curriculumDesc() {
        return this.$store.getters.Curriculum.curriculumDesc;
      },
    }
  }
</script>

<style scoped>
.v-expansion-panel-header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.property-label {
  margin: 0 4px 0 0;
  font-weight: bolder;
}
</style>