export default {
  props: {
    autoRefreshStatus: {
      type: Boolean,
      default: false,
    },
    autoRefreshInterval: {
      type: Number,
      default: 60,
    }
  },
  data() {
    return {
      hangfireTimer: {},
      timerLastRun: '',
    };
  },
  computed: {
    LastRunTime() {
      return this.timerLastRun.toLocaleString();
    },
  },
  methods: {
    fetchHangfireStatuses() {
      console.debug('Fetching Hangfire Status');
      this.$store.dispatch('getHangfireStatuses');
      this.timerLastRun = new Date();
    },
  },
  mounted() {
    this.fetchHangfireStatuses();
  },
  watch: {
    autoRefreshStatus(newValue) {
      if (newValue) {
        // Fetch initial status call
        this.$store.dispatch('getHangfireStatuses');
        // Set timer interval for next update call
        this.hangfireTimer = setInterval(this.fetchHangfireStatuses, this.$props.autoRefreshInterval * 1000);
      } else {
        if (this.hangfireTimer) {
          clearInterval(this.hangfireTimer);
        }
      }
    },
    autoRefreshInterval(newValue) {
      if (this.hangfireTimer) {
        clearInterval(this.hangfireTimer);
      }
      this.hangfireTimer = setInterval(this.fetchHangfireStatuses, newValue * 1000);
    }
  }
}