<template>
  <v-container fluid>
    <v-row v-if="!creatingPublish">
      <v-col>
        <v-row class="pb-0">
          <v-col>Previous Publish Status</v-col>
          <v-spacer></v-spacer>
          <v-col style="text-align: right;">
            Create Publish
            <v-btn icon @click="creatingPublish = true">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <div class="table-container">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Date</th>
                      <th class="text-left">Source</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(status, index) in statuses" :key="index">
                      <td>{{ status.Date }}</td>
                      <td>{{ status.Source }}</td>
                      <td>
                        {{ status.Status }}
                        <v-btn icon @click="statusActionClick(status)">
                          <v-icon :color="iconColor(status)">{{ iconClass(status) }}</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
        <div style="text-align: right;">
          <span>Status as of: {{ LastRunTime }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col sm="6" lg="4" xl="3">
        <v-row>
          <v-col>
            <tree-view tree-container-class="scroll-content with-buttons"
              :curriculum-guid="curriculumGuid"
              @selected="itemSelected"
              @checked="itemChecked"
              :show-search="false"
              :show-option-filter="true"
              :filter-options="platforms"
              filter-options-placeholder="Target Platform"
              filter-option-object-param="platforms">
            </tree-view>
          </v-col>
        </v-row>
        <v-row align-content="end">
          <v-col align="right" align-content="end">
            <v-btn class="mr-1" color="white" @click="creatingPublish = false">Cancel</v-btn>
            <v-btn color="primary" @click="publishDialog.showDialog=true">Publish</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="6" lg="8" xl="9" class="content-col scroll-content">
        <v-row>
          <v-col>
            <curriculum-details v-if="SelectedNodeType === 'Curriculum'">
            </curriculum-details>
            <assessment-details v-if="SelectedNodeType === 'Assessment'">
            </assessment-details>
            <question-details v-if="SelectedNodeType === 'Question'">
            </question-details>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title primary-title>
                <v-icon>mdi-plus</v-icon>Standards
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      width="60%"
      v-model="publishDialog.showDialog">
      <v-card rounded shaped class="dialog-content">
        <v-card-title>Publish Settings</v-card-title>
        <v-card-text>
          <v-form ref="publishForm">
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    :items="standards"
                    v-model="publishDialog.selectedStandard"
                    :rules="[publishDialog.rules.required]"
                    item-text="standardSetName"
                    return-object
                    placeholder="Selected Standard Set">
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Math ML Import Options
                  <v-radio-group v-model="publishDialog.mathMLOption">
                    <v-radio value="0" label="Yes, include MathML as XML"></v-radio>
                    <v-radio value="1" label="No, include MathML as Images"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="resetModal">Cancel</v-btn>
          <v-btn color="primary" @click="publishQtiPackage">Publish</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="50%"
      v-model="errorDialog.show">
      <v-card>
        <v-card-title>Error Details</v-card-title>
        <v-card-text>{{ errorDialog.text }}</v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TreeView from '../components/TreeView.vue';
import CurriculumDetails from '../components/CurriculumDetails.vue';
import AssessmentDetails from '../components/AssessmentDetails.vue';
import QuestionDetails from '../components/QuestionDetails.vue';

import QtiService from '../services/qti.service';
import timerVue from '../mixins/timer.vue';

export default {
  components: {
    TreeView,
    CurriculumDetails,
    AssessmentDetails,
    QuestionDetails,
},
  mixins: [timerVue],
  props: {
    curriculumGuid: {
      type: String
    },
  },
  data() {
    return {
      platforms: [{
        text: 'QTI IMS 2.2',
        value: 'Plat1',
      }],
      selectedNode: {},
      checkedNodes: [],
      creatingPublish: false,
      publishDialog: {
        showDialog: false,
        selectedStandard: '',
        mathMLOption: 0,
        rules: {
          required: value => !!value || 'Required',
        }
      },
      errorDialog: {
        show: false,
        text: '',
      },
    };
  },
  computed: {
    SelectedNodeType() {
      switch (this.selectedNode.type) {
        case 'TEST':
          return 'Assessment';
        case 'QUESTION':
          return 'Question';
        case 'ROOTFOLDER':
          return 'Curriculum';
        default:
          return '';
      }
    },
    Curriculum() {
      return this.$store.getters.Curriculum;
    },
    statuses() {
      return this.$store.getters.HangfireExportStatus;
    },
    standards() {
      const stds = [];
      stds.push({
        id: -1,
        standardSetName: 'Do Not Include Standards',
        docIdentifier: '',
      });
      stds.push(this.$store.getters.CurriculumStandards);
      return stds.flat();
    }
  },
  methods: {
    itemSelected(node) {
      console.debug('Node Selected: %O', node);
      this.selectedNode = node;
      this.$store.dispatch('setSelectedNode', node);
    },
    itemChecked(nodes) {
      console.log('Checked Node: %O', nodes);
      this.checkedNodes = nodes;
    },
    resetModal() {
      this.$refs.publishForm.reset();
      this.publishDialog.showDialog = false;
    },
    showErrorDetail(text) {
      this.errorDialog.text = text;
      this.errorDialog.show = true;
    },
    statusActionClick(status) {
      if (status.Status === 'Succeeded' && status.Detail && status.Detail.length > 0) {
        window.open(status.Detail, '_blank').focus();
        return;
      }
      if (status.Status === 'Succeeded' && (!status.Detail || status.Detail.length < 1)) {
        this.showErrorDetail('Invalid publish package path');
        return;
      }
      this.showErrorDetail(status.Detail);
    },
    iconClass(status) {
      if (status.Status === 'Succeeded' && status.Detail && status.Detail.length > 0) {
        return 'mdi-folder-zip';
      }
      if (status.Status === 'Succeeded' && (!status.Detail || status.Detail.length < 1)) {
        return 'mdi-folder-zip-outline';
      }
      if (status.Status === 'Enqueued') {
        return 'mdi-timer-sand-full';
      }
      return 'mdi-alert-circle'
    },
    iconColor(status) {
      if (status.Status === 'Succeeded' && status.Detail && status.Detail.length > 0) {
        return 'green';
      }
      if (status.Status === 'Succeeded' && (!status.Detail || status.Detail.length < 1)) {
        return '';
      }
      if (status.Status === 'Enqueued') {
        return 'gray';
      }
      return 'red'
    },
    async publishQtiPackage() {
      console.log('Publish Clicked');
      if (!this.$refs.publishForm.validate()) {
        console.log('Form Invalid');
        return;
      }
      this.$store.dispatch('toggleLoadingOverride');
      const publishPackage = [];
      let assessment = {};

      this.checkedNodes.forEach((node) => {
        assessment = publishPackage.find((n) => n.assessmentReference === node.assessmentReference);
        if (!assessment) {
          publishPackage.push({
            assessmentReference: node.assessmentReference,
            questionsReferences: [
              node.sysId,
            ],
          });
        } else {
          assessment.questionsReferences.push(node.sysId);
        }
      });

      const standardGuid = this.publishDialog.selectedStandard.id === -1 ? '' : this.publishDialog.selectedStandard.code;
      try {
        await QtiService.Packages.PublishPackage(
          this.$store.getters.CurriculumGuid,
          this.$store.getters.WebconUser,
          standardGuid,
          this.publishDialog.mathMLOption,
          publishPackage,
        );
      } catch(err) {
        // Log error
      }
      
      // Fetch Job Statuses to update UI
      this.$store.dispatch('getHangfireStatuses');

      // Clear the Add process
      this.creatingPublish = false;
      this.resetModal();
      this.fetchHangfireStatuses();

      this.$store.dispatch('toggleLoadingOverride');
    }
  },
}
</script>

<style scoped>
.form-row.with-buttons {
  min-height: 350px;
  max-height: 350px;
}
.table-container {
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
}
</style>